import FooterWPPostsView from './FooterWPPostsView'
import { useLatestBlogPosts } from '../../../hooks/useLatestBlogPosts'

export interface IFooterWPPostsProps {
  count?: number
  nofollow?: boolean
}

const FooterWPPosts = (props: IFooterWPPostsProps) => {
  const posts = useLatestBlogPosts(props.count || 3)

  return (
    <FooterWPPostsView posts={posts} nofollow={props.nofollow} />
  )
}

export default FooterWPPosts
