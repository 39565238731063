import React from 'react'
import { IStrapiSectionBackground } from '../../types/strapi'
import BackgroundImage from '../background-image/BackgroundImage'
import { BackgroundColor, StyledSection } from './Section.styled'
import { useSectionsContext } from '../../hooks/useSectionsContext'
import { ISectionData } from '../../utils/renderSection'
import { useTheme } from '@mui/material'

export interface ISectionProps {
  background?: IStrapiSectionBackground
  section?: ISectionData
  children: React.ReactNode
}

const Section: React.FC<ISectionProps> = ({ background, section, children }) => {

  const { modifiersMap } = useSectionsContext()
  const theme = useTheme()

  const modifiers = section ? modifiersMap.get(section) : undefined
  const paddingTop = modifiers?.extraTopPadding ? modifiers.extraTopPadding + 'px' : undefined
  const paddingBottom = modifiers?.extraBottomPadding ? modifiers.extraBottomPadding + 'px' : undefined

  return (
    <StyledSection
      sx={{
        paddingTop,
        paddingBottom,
      }}
    >
      <BackgroundColor color={theme.palette.background.default} />

      {background && (
        <BackgroundImage {...background} />
      )}

      {background?.overlay && (
        <BackgroundColor color={background.overlay} />
      )}

      {children}
    </StyledSection>
  )
}

export default Section
