import { styled } from '@mui/material/styles'
import Link from '../../link'

export const MenuLink = styled(Link)(({ theme }) => ({
  'display': 'flex',
  'alignItems': 'center',
  'color': theme.palette.text.primary,
  'fontWeight': 500,
  'fontSize': 14,
  'transition': 'color ease-in-out .1s',
  'marginRight': 'auto',
  'gap': theme.spacing(4),

  ':hover': {
    'color': theme.palette.primary.main,
  },

  [theme.breakpoints.down('lg')]: {

  },
}))

export const LinkIconWrapper = styled('div')(({ theme }) => ({
  width: 32,
  height: 32,

  [theme.breakpoints.down('lg')]: {
    width: 24,
    height: 24,
  },
}))
