import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const BackArrow: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M23.4546 11.4412H1.86039L6.38211 6.91945C6.59599 6.70706 6.59721 6.36151 6.38483 6.14767C6.17244 5.93378 5.82688 5.93256 5.61304 6.14495L0.158622 11.5994C-0.0528741 11.8121 -0.0528741 12.1557 0.158622 12.3684L5.61309 17.8229C5.80902 18.0517 6.15336 18.0784 6.38215 17.8824C6.61095 17.6864 6.63762 17.3421 6.44164 17.1133C6.42336 17.092 6.40348 17.0721 6.38215 17.0538L1.86044 12.5321H23.4546C23.7559 12.5321 24 12.2879 24 11.9867C24 11.6855 23.7558 11.4412 23.4546 11.4412Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default BackArrow
