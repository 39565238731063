import { TypographyProps } from '@mui/material'
import { IStrapiTitleComponent } from '../types/strapi'

const castStrapiTitleVariant = (component: IStrapiTitleComponent): TypographyProps['variant'] => {
  switch (component) {
    case IStrapiTitleComponent.H1:
      return 'h1'

    case IStrapiTitleComponent.H2:
      return 'h2'

    case IStrapiTitleComponent.H3:
      return 'h3'

    case IStrapiTitleComponent.H4:
      return 'h4'

    case IStrapiTitleComponent.PARAGRAPH:
      return 'body1'

    default:
      return 'body1'
  }
}

export default castStrapiTitleVariant
