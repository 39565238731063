import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

export const AddressTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '24px',
  marginLeft: theme.spacing(4),

  [theme.breakpoints.down('xl')]: {
    marginLeft: theme.spacing(3),
  },

  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(2),
  },

  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(1),
  },
}))

export const AddressText = styled('address')(({ theme }) => ({
  marginTop: theme.spacing(4),
  color: theme.palette.text.primary,
  fontSize: 12,
  fontWeight: 400,
  fontStyle: 'normal',
  whiteSpace: 'pre-line',

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },

  [theme.breakpoints.up('lg')]: {
    fontSize: 16,
  },
}))
