import Button from '../../button'
import { IStrapiTopBar } from '../../../types/strapi'
import SectionLayout from '../../../layouts/section-layout/SectionLayout'
import Title from '../../title/Title'
import { Box, Stack } from '@mui/material'

export interface ITopBarSectionData extends IStrapiTopBar { }

export interface ITopBarSectionProps {
  data: ITopBarSectionData
}

const TopBarSection = ({ data }: ITopBarSectionProps) => {
  const { theme, title, button, disabled } = data

  if (disabled) {
    return null
  }

  return (
    <Box display={{xs: 'none', lg: 'block'}}>
      <SectionLayout options={{ theme }}>
        <Stack
          p='10px'
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={4}
        >
          <Title title={title} fontSize={14} fontWeight={500} />

          <Button {...button} newTab={true} />
        </Stack>
      </SectionLayout>
    </Box>
  )
}

export default TopBarSection
