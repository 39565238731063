import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const MenuIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' {...props}>
      <path d='M18.125 10.625H1.875C1.70924 10.625 1.55027 10.5592 1.43306 10.4419C1.31585 10.3247 1.25 10.1658 1.25 10C1.25 9.83424 1.31585 9.67527 1.43306 9.55806C1.55027 9.44085 1.70924 9.375 1.875 9.375H18.125C18.2908 9.375 18.4497 9.44085 18.5669 9.55806C18.6842 9.67527 18.75 9.83424 18.75 10C18.75 10.1658 18.6842 10.3247 18.5669 10.4419C18.4497 10.5592 18.2908 10.625 18.125 10.625ZM18.75 14.375C18.75 14.2092 18.6842 14.0503 18.5669 13.9331C18.4497 13.8158 18.2908 13.75 18.125 13.75H1.875C1.70924 13.75 1.55027 13.8158 1.43306 13.9331C1.31585 14.0503 1.25 14.2092 1.25 14.375C1.25 14.5408 1.31585 14.6997 1.43306 14.8169C1.55027 14.9342 1.70924 15 1.875 15H18.125C18.2908 15 18.4497 14.9342 18.5669 14.8169C18.6842 14.6997 18.75 14.5408 18.75 14.375ZM18.75 5.625C18.75 5.45924 18.6842 5.30027 18.5669 5.18306C18.4497 5.06585 18.2908 5 18.125 5H1.875C1.70924 5 1.55027 5.06585 1.43306 5.18306C1.31585 5.30027 1.25 5.45924 1.25 5.625C1.25 5.79076 1.31585 5.94973 1.43306 6.06694C1.55027 6.18415 1.70924 6.25 1.875 6.25H18.125C18.2908 6.25 18.4497 6.18415 18.5669 6.06694C18.6842 5.94973 18.75 5.79076 18.75 5.625Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default MenuIcon
