import { IStrapiSimpleLink, IStrapiSubMenu } from '../../../types/strapi'
import useDesktopMenu from './useDesktopMenu'
import DesktopMenuView from './DesktopMenuView'

export interface IDesktopMenuProps {
  subMenus: Array<IStrapiSubMenu>
  links: Array<IStrapiSimpleLink>
}

const DesktopMenu = (props: IDesktopMenuProps) => {
  const {
    subMenus,
    links,
  } = props

  const {
    popupState,
    tabIndex,
    panelOffset,
    menuWrapperRef,
    handleSubMenuChange,
  } = useDesktopMenu()

  return (
    <DesktopMenuView
      subMenus={subMenus}
      links={links}
      popupState={popupState}
      tabIndex={tabIndex}
      panelOffset={panelOffset}
      menuWrapperRef={menuWrapperRef}
      handleSubMenuChange={handleSubMenuChange}
    />
  )
}

export default DesktopMenu
