import { ILinkProps } from '../components/link'
import { IStrapiBaseLink } from '../types/strapi'

const transformStrapiLink = (link: IStrapiBaseLink): ILinkProps => {
  const { url, ...rest } = link

  return {
    to: link.url,
    ...rest,
  }
}

export default transformStrapiLink
