import { IStrapiTitleComponent } from '../types/strapi'

const castStrapiTitleBottomMargin = (component: IStrapiTitleComponent): Record<'xs' | 'md' | 'lg', number> => {
  switch (component) {
    case IStrapiTitleComponent.H1:
      return { xs: 4, md: 6, lg: 8 }

    case IStrapiTitleComponent.H2:
      return { xs: 4, md: 5, lg: 6 }

    case IStrapiTitleComponent.H3:
      return { xs: 3, md: 3, lg: 3 }

    case IStrapiTitleComponent.H4:
      return { xs: 3, md: 3, lg: 3 }

    case IStrapiTitleComponent.PARAGRAPH:
      return { xs: 2, md: 2, lg: 2 }

    default:
      return { xs: 0, md: 0, lg: 0 }
  }
}

export default castStrapiTitleBottomMargin
