import React from 'react'
import { IStrapiAddress } from '../../../types/strapi'
import Image from '../../image'
import { AddressText, AddressTitle } from './FooterAddress.styled'
import Stack from '@mui/material/Stack'
import Box, { BoxProps } from '@mui/material/Box'

interface IFooterAddressProps extends IStrapiAddress {
  sx: BoxProps['sx']
}

const FooterAddress: React.FC<IFooterAddressProps> = (props) => {
  const { icon, address, title, sx } = props

  return (
    <Box mr={2} sx={sx}>
      <Stack direction='row' alignItems='center'>
        <Image image={icon} sx={{ width: 24, height: 24 }} />
        {title && (
          <AddressTitle color='text.primary'>{title}</AddressTitle>
        )}
      </Stack>

      <AddressText>
        {address}
      </AddressText>
    </Box>
  )
}

export default FooterAddress
