import React, { useState } from 'react'

export interface ICtaContext {
  ctaData: Record<string, string>
  setCtaData: (ctaData: Record<string, string>) => void
}

export const CtaContext = React.createContext<ICtaContext>({
  ctaData: {},
  setCtaData: () => {},
})

export type IFormContextProviderProps = React.PropsWithChildren

export const CtaProvider = (props: IFormContextProviderProps) => {
  const [ ctaData, setCtaData ] = useState({})

  return (
    <CtaContext.Provider value={{ ctaData, setCtaData }}>
      {props.children}
    </CtaContext.Provider>
  )
}
