import { ImgHTMLAttributes } from 'react'
import { IStrapiImage } from '../types/strapi'
import { getImageSrc } from './getImageSrc'

export interface IImageData {
  image: IStrapiImage
  isLazy?: boolean
  maxWidth?: number
}

const transformStrapiImage = ({ image, isLazy = true }: IImageData): ImgHTMLAttributes<HTMLImageElement> => {
  const width = parseInt(image.width || '')
  const height = parseInt(image.height || '')
  const imageUrl = getImageSrc(image)

  return {
    src: imageUrl,
    width: width,
    height: height,
    alt: image.alternativeText,
    ...(isLazy && { loading: 'lazy' }),
    ...(image.caption && { title: image.caption }),
  }
}

export default transformStrapiImage
