import React, { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { IStrapiFile } from '../../types/strapi'

interface IButtonWithChildren {
  children: ReactNode
  label?: never
}

interface IButtonWithLabel {
  children?: never
  label: string
}

export type IDownloadButtonProps = (IButtonWithChildren | IButtonWithLabel) & {
  file?: IStrapiFile
  buttonProps: MuiButtonProps
}

const DownloadButton: React.FC<IDownloadButtonProps> = (props) => {
  const { children, label, file, buttonProps } = props

  if (!file) {
    return null
  }

  const downloadProps = {
    href: file.localFile?.publicURL,
    download: file.name || true,
  }

  return (
    <MuiButton
      {...buttonProps}
      {...downloadProps}
    >
      {children || label}
    </MuiButton>
  )
}

export default styled(DownloadButton)({
  whiteSpace: 'nowrap',
})
