import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const PercentIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 29 29' {...props}>
      <path d='M21.0717 6.71438L6.72845 21.0571C6.37456 21.411 6.37456 21.9847 6.72845 22.3386C7.08234 22.6925 7.656 22.6925 8.00989 22.3386L22.3531 7.99582C22.707 7.64193 22.707 7.06827 22.3531 6.71438C22.1759 6.53721 21.9444 6.44885 21.7124 6.44885C21.4804 6.44885 21.2488 6.53766 21.0717 6.71438Z' fill='currentColor' />
      <path d='M21.7654 16.028C19.2886 16.028 17.274 18.043 17.274 20.5198C17.274 22.997 19.2886 25.0121 21.7654 25.0121C24.2707 25.0121 26.3093 22.997 26.3093 20.5198C26.3093 18.043 24.2707 16.028 21.7654 16.028ZM21.7654 23.1996C20.2882 23.1996 19.0865 21.9974 19.0865 20.5198C19.0865 19.0422 20.2882 17.8405 21.7654 17.8405C23.297 17.8405 24.4968 19.0177 24.4968 20.5198C24.4968 22.0228 23.297 23.1996 21.7654 23.1996Z' fill='currentColor' />
      <path d='M2.71694 8.47973C2.71694 10.986 4.756 13.025 7.26223 13.025C9.73947 13.025 11.7545 10.986 11.7545 8.47973C11.7545 6.00249 9.73947 3.98745 7.26224 3.98745C4.756 3.98745 2.71694 6.00249 2.71694 8.47973ZM7.26224 5.79995C8.73988 5.79995 9.94202 7.00209 9.94202 8.47973C9.94202 10.0122 8.7648 11.2125 7.26223 11.2125C5.75559 11.2125 4.52944 9.98637 4.52944 8.47973C4.52944 7.00209 5.75559 5.79995 7.26224 5.79995Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default PercentIcon
