import { styled } from '@mui/material/styles'
import Link from '../../link'
import Popover from '@mui/material/Popover'

export const MobileMenuLink = styled(Link)(({ theme }) => ({
  'color': theme.palette.text.primary,
  'fontWeight': 500,
  'fontSize': '18px',
  'lineHeight': '18px',
  'whiteSpace': 'nowrap',
  'transition': 'color ease-in-out .1s',
  'padding': 14,
  ':hover': {
    color: theme.palette.primary.main,
  },
}))

export const MobileMenuPopover = styled(Popover)(({ theme }) => ({
  'height': 'calc(100vh - 60px)',
  'top': 60,

  '& .MuiBackdrop-root': {
    'position': 'absolute',
    'background': theme.palette.background.default,
    'backdropFilter': 'blur(10px)',
    'zIndex': 0,
  },

  '& .MuiPaper-root': {
    top: '0 !important',
    borderRadius: 0,
    minWidth: '100%',
    bottom: 0,
    boxShadow: 'inset 0px 40px 40px -40px rgba(79, 79, 79, 0.25)',
    background: 'unset',
  },
}))
