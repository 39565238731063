export const getStrapiImageUrl = (url: string): string => {
  const apiUrl = process.env.STRAPI_API_URL

  const correctedApiUrl =
    apiUrl?.slice(-1) === '/' ? apiUrl.slice(0, -1) : apiUrl

  const correctedUrl = url.startsWith('/') ? url.slice(1) : url

  if (url.startsWith('http')) {
    return url
  }

  return `${correctedApiUrl}/${correctedUrl}`
}
