import React from 'react'
import List from '@mui/material/List'
import { IStrapiFooterSection } from '../../../types/strapi'
import { FooterListItem } from '../footer-link/FooterLink.styled'
import FooterLink from '../footer-link/FooterLink'
import FooterWPPosts from '../footer-wp-posts/FooterWPPosts'

export interface IFooterSectionProps extends IStrapiFooterSection {}

const FooterSection: React.FC<IFooterSectionProps> = ({ links, wpPosts }) => {

  return (
    <List sx={{ p: 0 }}>
      {links.map((footerLink, index) => (
        <FooterListItem key={index} isTitle={footerLink.isTitle}>
          <FooterLink {...footerLink} />
        </FooterListItem>
      ))}

      {wpPosts && (
        <FooterWPPosts count={wpPosts.postsCount} nofollow={wpPosts.nofollow} />
      )}
    </List>
  )
}

export default FooterSection
