import PercentIcon from '../../icons/Percent'
import { IButtonProps } from './Button'
import { Box, Button as MuiButton } from '@mui/material'

interface IOwnProps {}

export type IWithPromoWrapperProps = IOwnProps & IButtonProps

const withPromoWrapper = (Button: React.FC<IButtonProps>): React.FC<IButtonProps> => {

  const ButtonWithPromo = (props: IButtonProps) => {

    const { promo, sx, ...restProps } = props

    if (promo) {
      return (
        <Box display='inline-flex' alignItems='stretch' width={props.fullWidth ? '100%' : 'auto'} sx={sx}>
          <Button
            {...restProps}
            sx={{
              '&:hover + *': {
                backgroundColor: (props.color || 'primary') + '.dark',
              },
            }}
          />

          <MuiButton
            variant={props.variant || 'contained'}
            color={props.color || 'primary'}
            size={props.size || 'medium'}
            sx={{
              pointerEvents: 'none',
              padding: '4px',
              minWidth: 'unset',
              display: {
                xs: 'none',
                md: 'inline-flex',
              },
            }}
          >
            <PercentIcon sx={{ width: 30, height: 30 }} />
          </MuiButton>
        </Box>
      )
    }

    return <Button {...restProps} sx={sx} />
  }

  ButtonWithPromo.displayName = 'ButtonWithPromo'

  return ButtonWithPromo
}

export default withPromoWrapper
