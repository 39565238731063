import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { IStrapiSimpleLink, IStrapiSubMenu } from '../../../types/strapi'
import { PopupState } from 'material-ui-popup-state/hooks'
import { bindHover, bindMenu } from 'material-ui-popup-state/hooks'
import MenuSection from '../menu-section'
import { BannerContainer, HoverSection, IBannerPlacement, MenuContainer, SubMenuTab, TabPanel, TopMenuLink } from './DesktopMenu.styled'
import MenuBanner from '../menu-banner/MenuBanner'
import transformStrapiLink from '../../../utils/transformStrapiLink'
import { useIsMobile } from '../../../hooks/useIsMobile'

export interface IDesktopMenuViewProps {
  subMenus: Array<IStrapiSubMenu>
  links: Array<IStrapiSimpleLink>
  popupState: PopupState
  tabIndex: number | false
  panelOffset: number
  menuWrapperRef: React.MutableRefObject<HTMLDivElement | null>
  handleSubMenuChange: (index: number) => (event: React.SyntheticEvent<HTMLDivElement>) => void
}

function a11yProps(index: number) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const DesktopMenuView = (props: IDesktopMenuViewProps) => {
  const {
    subMenus,
    links,
    popupState,
    tabIndex,
    panelOffset,
    menuWrapperRef,
    handleSubMenuChange,
  } = props

  const isMobile = useIsMobile('lg')

  return (
    <>
      <Stack direction='row' ref={menuWrapperRef} sx={{ display: { xs: 'none', lg: 'flex' } }}>
        <Box {...bindHover(popupState)}>
          <Tabs aria-label='menu' value={tabIndex}>
            {subMenus.map((subMenu: IStrapiSubMenu, index: number) => (
              <SubMenuTab
                {...a11yProps(index)}
                key={subMenu.label}
                label={subMenu.label}
                onMouseEnter={handleSubMenuChange(index)}
                wrapped={false}
                disableRipple
              />
            ))}
          </Tabs>
        </Box>

        {links.map(({ label, link }: IStrapiSimpleLink) => (
          <TopMenuLink {...transformStrapiLink(link)} key={label}>
            {label}
          </TopMenuLink>
        ))}
      </Stack>

      {!isMobile && (
        <HoverSection
          keepMounted
          {...bindMenu(popupState)}
          onContextMenu={undefined} // fix right click
        >
          <MenuContainer>
            <BannerContainer placement={tabIndex && tabIndex >= 2 ? IBannerPlacement.LEFT : IBannerPlacement.RIGHT}>
              {subMenus.map(({ banner }, index) => {
                if (!banner) {
                  return null
                }

                return (
                  <TabPanel role='tabpanel' hidden={tabIndex !== index} key={index} sx={{ display: tabIndex !== index ? 'none' : 'flex', width: '100%' }}>
                    <MenuBanner {...banner} />
                  </TabPanel>
                )
              })}
            </BannerContainer>

            {subMenus.map((subMenu: IStrapiSubMenu, index: number) => (
              <TabPanel
                role='tabpanel'
                hidden={tabIndex !== index}
                key={subMenu.label}
                style={{
                  left: panelOffset,
                }}
              >
                <Grid container>
                  {subMenu.sections.map((section, index) => (
                    <Grid item key={index} xs={3}>
                      <MenuSection section={section} onMenuClose={popupState.close} />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            ))}
          </MenuContainer>
        </HoverSection>
      )}
    </>
  )
}

export default DesktopMenuView
