import { IStrapiTitleComponent } from '../types/strapi'

const castStrapiTitleComponent = (component: IStrapiTitleComponent) => {
  switch (component) {
    case IStrapiTitleComponent.H1:
      return 'h1'

    case IStrapiTitleComponent.H2:
      return 'h2'

    case IStrapiTitleComponent.H3:
      return 'h3'

    case IStrapiTitleComponent.H4:
      return 'h4'

    case IStrapiTitleComponent.PARAGRAPH:
      return 'p'

    default:
      // eslint-disable-next-line no-console
      console.log('Unknown title component', component)
      return 'p'
  }
}

export default castStrapiTitleComponent
