import React from 'react'
import Container from '@mui/material/Container'
import { IStrapiSectionOptions } from '../../types/strapi'
import Section from '../../components/section/Section'
import ThemeContainer from '../../components/theme-container/ThemeContainer'
import transformStrapiPadding from '../../utils/transformStrapiPadding'
import MaxWidthContainer from '../../components/max-width-container/MaxWidthContainer'
import { ISectionData } from '../../utils/renderSection'

export interface ISectionLayoutProps {
  options: IStrapiSectionOptions
  children: React.ReactNode
  section?: ISectionData
}

const SectionLayout: React.FC<ISectionLayoutProps> = ({ children, options, section }) => {
  const { theme, background, anchor, width, paddingTop, paddingBottom } = options

  return (
    <ThemeContainer theme={theme}>
      <Section background={background} section={section}>
        <Container
          id={anchor || undefined}
          disableGutters={false}
          sx={{
            paddingTop: transformStrapiPadding(paddingTop),
            paddingBottom: transformStrapiPadding(paddingBottom),
          }}
        >
          <MaxWidthContainer maxWidth={width}>
            {children}
          </MaxWidthContainer>
        </Container>
      </Section>
    </ThemeContainer>
  )
}

export default SectionLayout
