import { graphql, useStaticQuery } from 'gatsby'
import { IStrapiFooter } from '../../types/strapi'

interface IFooterResponse {
  strapiFooter: IStrapiFooter
}

const useFooter = () => {
  const { strapiFooter }: IFooterResponse = useStaticQuery(graphql`
    query {
      strapiFooter {
        ...StrapiFooter
      }
    }
  `)

  return strapiFooter
}

export default useFooter
