import { IStrapiSectionWidth } from '../types/strapi'

const transformStrapiSectionWidth = (width?: IStrapiSectionWidth) => {
  switch (width) {
    case IStrapiSectionWidth.FULL_WIDTH:
      return 1200

    case IStrapiSectionWidth.MAX_700:
      return 700

    case IStrapiSectionWidth.MAX_800:
      return 800

    case IStrapiSectionWidth.MAX_900:
      return 900

    default:
      return 1200
  }
}

export default transformStrapiSectionWidth
