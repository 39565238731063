import { Typography, TypographyProps } from '@mui/material'
import { IStrapiTitle } from '../../types/strapi'
import castStrapiTitleVariant from '../../utils/castStrapiTitleVariant'
import castStrapiTitleComponent from '../../utils/castStrapiTitleComponent'
import castStrapiTitleBottomMargin from '../../utils/castStrapiTitleBottomMargin'

export interface ITitleProps extends Omit<TypographyProps, 'title'> {
  title?: IStrapiTitle | null
  hasSpacing?: boolean
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'p'
}

const Title = (props: ITitleProps) => {
  const { title, hasSpacing = false, component, ...rest } = props

  if (!title) {
    return null
  }

  return (
    <Typography
      variant={castStrapiTitleVariant(title.component)}
      component={component || castStrapiTitleComponent(title.component)}
      color='text.primary'
      {...hasSpacing && {
        marginBottom: castStrapiTitleBottomMargin(title.component),
      }}
      {...rest as any} // TODO: https://github.com/mui/material-ui/issues/19512
    >
      {title.text}
    </Typography>
  )
}

export default Title
