import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CloseIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 16 16' {...props}>
      <path fillRule='evenodd' d='M0.781384 16C0.626848 16.0001 0.475776 15.9543 0.347275 15.8684C0.218774 15.7826 0.118619 15.6606 0.0594767 15.5178C0.000334656 15.375 -0.0151367 15.2179 0.0150196 15.0663C0.0451759 14.9148 0.119605 14.7756 0.228892 14.6663L14.6662 0.228852C14.8127 0.0823206 15.0114 0 15.2187 0C15.4259 0 15.6246 0.0823206 15.7711 0.228852C15.9177 0.375384 16 0.574123 16 0.78135C16 0.988577 15.9177 1.18732 15.7711 1.33385L1.33388 15.7713C1.26139 15.8439 1.17526 15.9015 1.08045 15.9408C0.985637 15.98 0.884002 16.0002 0.781384 16Z' fill='currentColor' />
      <path fillRule='evenodd' d='M15.2186 16C15.116 16.0002 15.0144 15.98 14.9195 15.9408C14.8247 15.9015 14.7386 15.8439 14.6661 15.7713L0.228849 1.33385C0.0823196 1.18732 0 0.988577 0 0.78135C0 0.574123 0.0823196 0.375384 0.228849 0.228852C0.375379 0.0823206 0.574116 0 0.781341 0C0.988565 0 1.1873 0.0823206 1.33383 0.228852L15.7711 14.6663C15.8804 14.7756 15.9548 14.9148 15.985 15.0663C16.0151 15.2179 15.9997 15.375 15.9405 15.5178C15.8814 15.6606 15.7812 15.7826 15.6527 15.8684C15.5242 15.9543 15.3732 16.0001 15.2186 16Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default CloseIcon
