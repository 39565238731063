
import React from 'react'
import { IStrapiImageLink } from '../../types/strapi'
import Image from '../image'
import Link from '../link'
import transformStrapiLink from '../../utils/transformStrapiLink'

export interface IImageLinkProps extends Partial<IStrapiImageLink> {
  sx?: Record<string, string | number>
  isLazy?: boolean
  cover?: boolean
}

const ImageLink: React.FC<IImageLinkProps> = (props) => {
  const { image, link, sx, isLazy = true, cover = false } = props

  if (!image) {
    return null
  }

  if (!link) {
    return (
      <Image image={image} isLazy={isLazy} cover={cover} sx={sx} />
    )
  }

  return (
    <Link {...transformStrapiLink(link)} aria-label={image.alternativeText}>
      <Image image={image} isLazy={isLazy} cover={cover} sx={sx} />
    </Link>
  )
}

export default React.memo(ImageLink)
