import React from 'react'
import useFooter from './useFooter'
import FooterView from './FooterView'

const Footer: React.FC = () => {
  const footerData = useFooter()

  if (!footerData) {
    return null
  }

  const {
    theme,
    logo,
    email,
    addresses,
    sections,
    commonLinks,
    copyright,
    socials,
    socialProps,
  } = footerData

  return (
    <FooterView
      theme={theme}
      logo={logo}
      email={email}
      addresses={addresses}
      sections={sections}
      commonLinks={commonLinks}
      copyright={copyright}
      socials={socials}
      socialProps={socialProps}
    />
  )
}

export default Footer
