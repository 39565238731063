import { createTheme, useTheme } from '@mui/material/styles'
import { IStrapiTheme } from '../types/strapi'

const useSectionTheme = (strapiTheme?: IStrapiTheme) => {
  const currentTheme = useTheme()

  if (!strapiTheme) {
    return currentTheme
  }

  return createTheme(currentTheme, {
    palette: {
      background: {
        default: strapiTheme.backgroundColor,
      },
      text: {
        primary: strapiTheme.primaryTextColor,
        secondary: strapiTheme.secondaryTextColor,
      },
    },
  })
}

export default useSectionTheme
