import React from 'react'
import HeaderView from './HeaderView'
import useHeader from './useHeader'

const Header: React.FC = () => {
  const { headerData, hasBlur, setBlur } = useHeader()

  return (
    <HeaderView
      {...headerData || {}}
      hasBlur={hasBlur}
      setBlur={setBlur}
    />
  )
}

export default Header
