import { common } from '@mui/material/colors'
import { Breakpoint, createTheme, darken, lighten } from '@mui/material/styles'
import { ContainerProps } from '@mui/material/Container'

declare module '@mui/material/styles/createPalette' {
  interface CustomPalette {
    custom: typeof customColors
    brand: (typeof basicColors & typeof blackColors & typeof accentColors & typeof nftColors & typeof gradients)
    tertiary: PaletteColor
    nft: PaletteColor
    'charcoal-gray': PaletteColor
  }
  interface Palette extends CustomPalette { }
  interface PaletteOptions extends CustomPalette { }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    link: React.CSSProperties
    wysiwyg: React.CSSProperties
  }
  interface TypographyVariantsOptions {
    link?: React.CSSProperties
    wysiwyg?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    link: true
    wysiwyg: true
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    text: true
  }
}

declare module '@mui/material/InputBase' {
  interface InputBasePropsColorOverrides {
    text: true
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    text: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    custom: typeof customColors
    brand: (typeof basicColors & typeof blackColors & typeof accentColors)
    tertiary: true
    nft: true
  }
}

declare module '@mui/material/Tabs' {
  interface TabsPropsIndicatorColorOverrides {
    tertiary: true
    text: true
  }
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1200,
  },
}

export const basicColors = {
  platinumGray: '#E5E5E5',
  brandedGray: '#788088',
  charcoalGray: '#343B4C',
}

export const blackColors = {
  lightGray: '#F7F7F7',
  black40: '#A8A8A8',
  black60: '#7D7D7D',
  blackishBlue: '#172028',
}

export const accentColors = {
  brandedRed: '#D01217',
  brightYellow: '#FFC11E',
  brightGreen: '#5CB836',
}

export const customColors = {
  platinumBlue: '#DADFE9',
  purpleBlue: '#545D8D',
  darkBlue: '#1D286D',
}

export const nftColors = {
  nftMain: '#A934F1',
  nftText: '#6C63FF',
}

export const gradients = {
  primaryGradient: 'linear-gradient(135deg, #D01217 15.43%, #970004 84.56%)',
  nftGradient: 'linear-gradient(140deg, #A934F199 0%, #9467DDBB 100%)',
  nftSubmitGradient: 'linear-gradient(155deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 38.36%, rgba(255, 255, 255, 0.28) 38.37%, rgba(255, 255, 255, 0.00) 80.52%), radial-gradient(145.35% 141.42% at 0% 0%, rgba(169, 52, 241, 0.60) 0%, rgba(148, 103, 221, 0.60) 100%)',
}

export const fontSizes = {
  h1: 48,
  h2: 36,
  h3: 28,
  quote: 24,
  h4: 20,
  p: 18,
}

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
}

const { palette: { augmentColor }, shadows } = createTheme()

const theme = createTheme({
  spacing: 4,
  breakpoints: breakpoints,
  palette: {
    'mode': 'light',
    'primary': {
      main: accentColors.brandedRed,
      contrastText: common.white,
    },
    'secondary': {
      main: customColors.darkBlue,
      contrastText: common.white,
    },
    'tertiary': augmentColor({
      color: {
        main: blackColors.lightGray,
      },
      name: 'tertiary',
    }),
    'nft': {
      main: nftColors.nftMain,
      light: lighten(nftColors.nftMain, 0.4),
      dark: darken(nftColors.nftMain, 0.3),
      contrastText: '#FFFFFF',
    },
    'charcoal-gray': augmentColor({
      color: {
        main: basicColors.charcoalGray,
      },
      name: 'tertiary',
    }),
    'background': {
      default: common.white,
    },
    'text': {
      primary: blackColors.blackishBlue,
      secondary: common.black,
      disabled: '#DADFE9',
    },
    'brand': { ...basicColors, ...blackColors, ...accentColors, ...nftColors, ...gradients },
    'custom': customColors,
  },
  typography: {
    fontFamily: ['Noto Sans', 'sans-serif'].join(','),
    fontSize: 20,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: 48,
      fontWeight: 700,
      lineHeight: '1.1em',
      whiteSpace: 'pre-line',
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: 40,
      },
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: 36,
        lineHeight: '1.2em',
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: 32,
      },
    },
    h2: {
      fontSize: 36,
      fontWeight: 700,
      lineHeight: '1.2em',
      whiteSpace: 'pre-line',
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: 28,
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: 24,
      },
    },
    h3: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '1.3em',
      whiteSpace: 'pre-line',
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: 24,
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: 20,
      },
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '1.3em',
      whiteSpace: 'pre-line',
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: 18,
      },
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '1.4em',
      whiteSpace: 'pre-line',
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: 18,
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: 16,
      },
    },
    body1: {
      fontSize: 20,
      fontWeight: 300,
      lineHeight: '1.4em',
      whiteSpace: 'pre-line',
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: 18,
        lineHeight: '1.5em',
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: 16,
        lineHeight: '1.6em',
      },
    },
    body2: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: '1.6em',
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
    link: {
      fontSize: 20,
      fontWeight: 300,
      color: accentColors.brandedRed,
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: 18,
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: 16,
      },
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'wysiwyg' && {
            'color': theme.palette.text.secondary,
            'whiteSpace': 'normal',
            'fontSize': 20,
            'fontWeight': 300,
            'lineHeight': '1.4em',
            [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
              fontSize: 18,
              lineHeight: '1.5em',
            },
            [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
              fontSize: 16,
              lineHeight: '1.6em',
            },

            '& *': {
              margin: 0,
              lineHeight: 'calc(5px + 1.69ex + 5px)', // h2 = 43px, p = 28px
              boxSizing: 'border-box',
            },

            '& h1': {
              color: theme.palette.text.primary,
              fontSize: 48,
              fontWeight: 700,
              lineHeight: '1.1em',
              whiteSpace: 'pre-line',
              marginBottom: 32,

              [theme.breakpoints.down('lg')]: {
                fontSize: 40,
              },
              [theme.breakpoints.down('md')]: {
                fontSize: 36,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 32,
              },
            },

            '& h2': {
              color: theme.palette.text.primary,
              fontSize: 36,
              fontWeight: 700,
              lineHeight: '1.2em',
              whiteSpace: 'pre-line',
              marginBottom: 24,

              [theme.breakpoints.down('lg')]: {
                fontSize: 28,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 24,
              },
            },

            '& h3': {
              color: theme.palette.text.primary,
              fontSize: 24,
              fontWeight: 600,
              lineHeight: '1.3em',
              whiteSpace: 'pre-line',
              marginBottom: 12,

              [theme.breakpoints.down('lg')]: {
                fontSize: 22,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
              },
            },

            '& h4': {
              color: theme.palette.text.primary,
              fontSize: 20,
              fontWeight: 600,
              lineHeight: '1.4em',
              whiteSpace: 'pre-line',
              marginBottom: 12,

              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            },

            '& .gatsby-body1': {
              fontSize: 20,
              fontWeight: 300,
              lineHeight: '1.4em',

              [theme.breakpoints.down('lg')]: {
                fontSize: 18,
                lineHeight: '1.5em',
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                lineHeight: '1.6em',
              },
            },

            '& .gatsby-body2': {
              fontSize: 16,
              fontWeight: 300,
              lineHeight: '1.6em',
            },

            '& h1:last-child, & h2:last-child, & h3:last-child, & h4:last-child': {
              marginBottom: 0,
            },

            '& table': {
              borderSpacing: 0,
              borderCollapse: 'collapse',
            },

            '& td': {
              verticalAlign: 'top',
              textAlign: 'left',
              minWidth: 16,
              padding: 8,
            },

            '&& td img, && td a': {
              display: 'inherit',
            },

            '& a': {
              textDecoration: 'none',
              color: theme.palette.primary.main,
            },
            '& a:hover *': {
              color: theme.palette.primary.main + '!important',
            },

            '& img': {
              maxWidth: '100%',
              display: 'inline-flex',
            },

            '& li': {
            },

            '& p:not(:last-child), & div.gatsby-body2:not(:last-child), & ul:not(:last-child), & ol:not(:last-child), & figure:not(:last-child), & table:not(:last-child)': {
              marginBottom: 8,
            },

            '& ul, & ol': {
              paddingTop: 4,
            },

            '& strong': {
              fontWeight: 500,
            },

            '& hr': {
              border: 'none',
              borderBottom: '2px solid #D9D9D9',
              paddingTop: theme.spacing(4),
              marginBottom: theme.spacing(4),
            },

            '& blockquote': {
              borderLeft: '2px solid',
              borderColor: theme.palette.primary.main,
              fontStyle: 'normal',
              margin: '16px 40px',
              padding: '0 16px',

              [theme.breakpoints.down('md')]: {
                margin: '8px 20px',
              },
            },
          }),
        }),
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl' as Breakpoint,
        disableGutters: true,
      } as Partial<ContainerProps>,
      styleOverrides: {
        root: {
          paddingLeft: 12,
          paddingRight: 12,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          textAlign: 'center',
          borderRadius: '4px',
          ...(ownerState.color === 'nft' && ownerState.variant === 'contained' && {
            'backgroundImage': gradients.nftGradient,
            'boxShadow': '0px 20px 20px -10px rgba(167, 99, 215, 0.4)',

            '&:hover': {
              'boxShadow': '0px 20px 20px -10px rgba(167, 99, 215, 0.4)',
            },
          }),

          ...(ownerState.color === 'nft' && ownerState.variant === 'outlined' && {
            'color': nftColors.nftText,
            'textShadow': '0 2px 40px rgba(0,0,0,0.25), 0 8px 40px #A963D7',
            'boxShadow': '0px 1px 2px 0px rgba(0, 0, 0, 0.24)',
            'border': 'none',

            '&:hover': {
              border: 'none',
            },
          }),
        }),
        sizeLarge: {
          fontSize: 16,
          lineHeight: '17px',
          fontWeight: 600,
          padding: '15px 36px',
        },
        sizeMedium: {
          fontSize: 15,
          lineHeight: '15px',
          fontWeight: 500,
          padding: '12px 26px',
        },
        sizeSmall: {
          fontSize: 12,
          fontWeight: 500,
          lineHeight: '13px',
          padding: '5px 16px',
        },
        contained: {
          border: '1px solid transparent',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '20px 25px',
          fontWeight: 700,
          alignItems: 'flex-start',
          flexGrow: 1,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: 500,
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        marginThreshold: 0,
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          'backgroundColor': 'transparent',
          'boxShadow': 'none',
          '&:before': { display: 'none' },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          'fontSize': 14,
          'transform': 'translate(14px, 14px) scale(1)',

          '&.Mui-focused': {
            transform: 'translate(14px, -40%) scale(0.75)',
          },
          '&.MuiFormLabel-filled': {
            transform: 'translate(14px, -40%) scale(0.75)',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        'root': {
          fontSize: 15,
          fontWeight: 300,
          lineHeight: 1.5,
        },
        'input': {
          'height': '24px',
          'padding': '8px 16px',
          'background': 'transparent',
          'fontSize': '15px',

          '&::placeholder': {
            opacity: 0.7,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '8px 16px',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          padding: '8px 16px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          background: 'transparent',
          height: '24px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          'borderRadius': '8px',
          'background': 'transparent',
          'boxShadow': shadows[1],
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          '.MuiCardActionArea-focusHighlight': {
            background: 'transparent',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 16,
        },
      },
    },
  },
})

export default theme
