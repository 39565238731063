import { graphql, useStaticQuery } from 'gatsby'
import { IStrapiHeader } from '../../types/strapi'
import { useState } from 'react'

interface IHeaderResponse {
  strapiHeader: IStrapiHeader
}

const useHeader = () => {
  const { strapiHeader }: IHeaderResponse = useStaticQuery(graphql`
    query {
      strapiHeader {
        ...StrapiHeader
      }
    }
  `)

  const [ hasBlur, setBlur ] = useState(false)

  return {
    headerData: strapiHeader,
    hasBlur,
    setBlur,
  }
}

export default useHeader
