import { FooterListItem } from '../footer-link/FooterLink.styled'
import { IStrapiBlogPost } from '../../../types/strapi'
import FooterLink from '../footer-link/FooterLink'

export interface IFooterWPPostsViewProps {
  posts: Array<IStrapiBlogPost>
  nofollow?: boolean
}

const FooterWPPostsView = (props: IFooterWPPostsViewProps) => {
  const { posts, nofollow } = props

  return (
    <>
      {posts.map((post, index) => (
        <FooterListItem key={index}>
          <FooterLink
            label={post.title}
            link={{
              url: '/blog/' + post.slug,
              nofollow,
            }}
          />
        </FooterListItem>
      ))}
    </>
  )
}

export default FooterWPPostsView
