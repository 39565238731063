import React from 'react'
import { IStrapiCase, IStrapiIndustry, IStrapiService } from '../../types/strapi'

export interface ICasesContext {
  cases: Array<IStrapiCase>
  industries: Array<IStrapiIndustry>
  services: Array<IStrapiService>
}

export const CasesContext = React.createContext<ICasesContext>({
  cases: [],
  industries: [],
  services: [],
})

export type ICasesProviderProps = Partial<ICasesContext> & React.PropsWithChildren

export const CasesProvider = ({ cases = [], services = [], industries = [], children }: ICasesProviderProps) => {

  return (
    <CasesContext.Provider value={{ cases, services, industries }}>
      {children}
    </CasesContext.Provider>
  )
}
