import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Header = styled('header')(() => ({
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  boxShadow: '0px 4px 40px 0px rgba(79, 79, 79, 0.25)',
  backdropFilter: 'blur(10px)',
  zIndex: 10000,
}))

export const BackDropBlur = styled(Box)(() => ({
  position: 'fixed',
  width: 'var(--fixed100vw, 100vw)',
  height: '100vh',
  backdropFilter: 'blur(10px)',
  zIndex: 100,
}))
