import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

export const BannerWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.default,
}))

export const BannerButton = styled('div')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '12px',
  color: theme.palette.text.secondary,
  borderRadius: 4,
  border: '1px solid',
  borderColor: theme.palette.text.secondary,
  padding: theme.spacing(2),
  marginRight: 'auto',
  marginTop: theme.spacing(3),
}))

export const ContentWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-around',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
}))

export const PicturesWrapper = styled('div')(() => ({
  position: 'relative',
  height: 'calc(100% - 40px)',
  top: '20px',
  maxWidth: '50%',
}))

export const PictureContainer = styled('div')<{ placement: number }>(({ placement }) => ({
  height: '100%',
  top: `calc(var(--pictures-step) * ${placement})`,
  left: `calc(var(--pictures-step) * ${placement})`,
  transformOrigin: 'top left',
  transform: `scale(0.7)`,
  filter: 'drop-shadow(0px 0px 40px rgba(106, 106, 106, 0.25))',
}))