import React from 'react'
import { IStrapiTheme } from '../../types/strapi'
import useSectionTheme from '../../hooks/useSectionTheme'
import { ThemeProvider } from '@mui/material'

export interface IThemeContainerProps extends React.PropsWithChildren {
  theme?: IStrapiTheme
}

const ThemeContainer = (props: IThemeContainerProps) => {
  const { theme, children } = props

  const mergedTheme = useSectionTheme(theme)

  return (
    <ThemeProvider theme={mergedTheme}>
      {children}
    </ThemeProvider>
  )
}

export default ThemeContainer
