import React from 'react'
import { IStrapiSectionWidth } from '../../types/strapi'
import { Box } from '@mui/material'
import transformStrapiSectionWidth from '../../utils/transformStrapiSectionWidth'

export interface IMaxWidthContainerProps extends React.PropsWithChildren {
  maxWidth?: IStrapiSectionWidth
}

const MaxWidthContainer = (props: IMaxWidthContainerProps) => {
  const { maxWidth, children } = props

  return (
    <Box maxWidth={transformStrapiSectionWidth(maxWidth)}>
      {children}
    </Box>
  )
}

export default MaxWidthContainer
