import { usePopupState } from 'material-ui-popup-state/hooks'
import { useEffect, useRef, useState } from 'react'

const useDesktopMenu = () => {
  const [ tabIndex, setTabIndex ] = useState<number | false>(false)
  const popupState = usePopupState({ variant: 'popover', popupId: 'desktopMenu' })
  const menuWrapperRef = useRef<HTMLDivElement | null>(null)
  const panelOffset = useRef<number>()

  const handleSubMenuChange = (newValue: number) => (event: React.SyntheticEvent<HTMLDivElement>) => {
    panelOffset.current = event.currentTarget.offsetLeft + (menuWrapperRef.current?.offsetLeft ?? 0)
    setTabIndex(newValue)
  }

  useEffect(() => {
    if (!popupState.isOpen) {
      setTabIndex(false)
    }
  }, [ popupState.isOpen ])

  return {
    popupState,
    tabIndex,
    handleSubMenuChange,
    panelOffset: panelOffset.current || 0,
    menuWrapperRef,
  }
}

export default useDesktopMenu
