import React, { useEffect } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { isClient } from '../../utils/isClient'
import { IStrapiPageLayout } from '../../types/strapi'
import { CasesProvider } from '../../contexts/cases-context-provider/CasesProvider'
import { IPageContext } from '../../templates/page'

export interface IPageLayoutProps extends React.PropsWithChildren {
  pageContext: IPageContext
}

const PageLayout = ({ children, pageContext }: IPageLayoutProps) => {

  useEffect(() => {
    if (!isClient()) {
      return
    }

    new ResizeObserver(() => {
      const fixed100vw = document.documentElement.clientWidth
      document.documentElement.style.setProperty('--fixed100vw', `${fixed100vw}px`)
    }).observe(document.documentElement)
  }, [])

  switch (pageContext.layout) {
    case IStrapiPageLayout.LANDING:
      return (
        <main>
          {children}
        </main>
      )

    case IStrapiPageLayout.CASES_HOME:
    case IStrapiPageLayout.CASES_FILTER:
      const { cases, services, industries } = pageContext
      return (
        <>
          <Header />
          <main>
            <CasesProvider cases={cases} services={services} industries={industries}>
              {children}
            </CasesProvider>
          </main>
          <Footer />
        </>
      )

    case IStrapiPageLayout.PAGE:
    default:
      return (
        <>
          <Header />
          <main>{children}</main>
          <Footer />
        </>
      )
  }
}

export default PageLayout
