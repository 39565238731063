import useMediaQuery from '@mui/material/useMediaQuery'
import { Breakpoint, useTheme } from '@mui/material/styles'

export const useIsMobile = (breakpoint: Breakpoint = 'lg'): boolean => {

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(breakpoint))

  return isMobile
}
