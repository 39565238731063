import { BoxProps } from '@mui/material/Box'
import { IStrapiPadding } from '../types/strapi'

const transformStrapiPadding = (padding?: IStrapiPadding | null): BoxProps['p'] => {

  switch (padding) {
    case IStrapiPadding.EXTRA_LARGE:
      return { xs: 20, md: 26, lg: 32, xl: 40 }

    case IStrapiPadding.LARGE:
      return { xs: 12, md: 16, lg: 20, xl: 24 }

    case IStrapiPadding.NORMAL:
      return { xs: 6, md: 8, lg: 10, xl: 12 }

    case IStrapiPadding.SMALL:
      return { xs: 6 }

    case IStrapiPadding.OFF:
      return 0

    default:
      return 0
  }
}

export default transformStrapiPadding
