import React from 'react'

export const LazyContext = React.createContext<{
  isLazy: boolean
}>({
  isLazy: false,
})

export interface ILazyProviderProps extends React.PropsWithChildren {
  isLazy: boolean
}

export const LazyProvider = (props: ILazyProviderProps) => {
  const { isLazy, children } = props

  if (!isLazy) {
    return <>{children}</>
  }

  return (
    <LazyContext.Provider value={{ isLazy: true }}>
      {children}
    </LazyContext.Provider>
  )
}
