import React from 'react'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby-link'
import { styled } from '@mui/material/styles'

const isExternalLink = (path?: string) =>
  path?.startsWith(`http://`) ||
  path?.startsWith(`https://`) ||
  path?.startsWith(`//`) ||
  path?.startsWith(`mailto`)

interface ILink extends React.PropsWithoutRef<Partial<GatsbyLinkProps<Record<string, unknown>>>> {
  newTab?: boolean
  nofollow?: boolean
}

interface ILinkWithHrefProp extends ILink {
  href: string
  url?: never
  to?: never
}

interface ILinkWithUrlProp extends ILink {
  href?: never
  url: string
  to?: never
}

interface ILinkWithToProp extends ILink {
  href?: never
  url?: never
  to: string
}

export type ILinkProps = ILinkWithHrefProp | ILinkWithUrlProp | ILinkWithToProp

const Link: React.FC<ILinkProps> = ({ children, nofollow, newTab = false, to, href, url, ...props }) => {
  const linkUrl = to || url || href as string

  // Fix internal links to wp pages (should not be Gatsby Link)
  if (linkUrl.startsWith('https://') && linkUrl.includes('simtechdev') && !newTab) {
    return (
      <a {...props} href={linkUrl}>
        {children}
      </a>
    )
  }

  if (props.target === '_blank' || newTab || isExternalLink(linkUrl)) {
    return (
      <a {...props} href={linkUrl} rel={`noopener noreferrer${nofollow ? ' nofollow' : ''}`} target='_blank'>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink {...props} to={linkUrl} {...(nofollow && { rel: 'nofollow' })}>
      {children}
    </GatsbyLink>
  )
}

const StyledLink = styled(
  React.forwardRef((props: ILinkProps, ref) => <Link {...props} />),
)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    textDecoration: 'none',
  }),
)

export default StyledLink
