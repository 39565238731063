import React from 'react'
import { ISocialsBlockProps } from './SocialsBlock'
import { isClient } from '../../utils/isClient'

const SocialsBlock = React.lazy(() => import('./SocialsBlock'))

const SocialsBlockLazy = (props: ISocialsBlockProps) => {
  const { socials } = props

  const SocialsFallback = () => (
    <>{socials.map((social) => <a key={social.name} href={social.url}>{social.name}</a>)}</>
  )

  if (!isClient()) {
    return (
      <SocialsFallback />
    )
  }

  return (
    <React.Suspense fallback={null}>
      <SocialsBlock {...props} />
    </React.Suspense>
  )
}

export default SocialsBlockLazy
