import { useContext } from 'react'
import { LazyContext } from '../contexts/lazy-provider/LazyProvider'

const useIsLazySection = (): boolean => {
  const { isLazy } = useContext(LazyContext)

  return isLazy ?? false
}

export default useIsLazySection
