import ButtonBase from '@mui/material/ButtonBase'
import Drawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

export const MobileDrawer = styled(Drawer)(() => ({
  'height': '100vh',

  '& .MuiPaper-root': {
    width: '100%',
    minHeight: '100vh',
    boxShadow: 'none',
  },
}))

export const MobileSubMenuTitle = styled(ButtonBase)(({ theme }) => ({
  'color': theme.palette.text.primary,
  'fontFamily': theme.typography.fontFamily,
  'fontWeight': 500,
  'fontSize': '18px',
  'lineHeight': '18px',
  'whiteSpace': 'nowrap',
  'transition': 'color ease-in-out .1s',
  'padding': 14,

  ':hover': {
    color: theme.palette.primary.main,
  },
}))

export const MobileSubMenuHeader = styled(Stack)(({ theme }) => ({
  boxShadow: '0px 4px 40px 0px rgba(79, 79, 79, 0.25)',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  minHeight: 60,
}))
