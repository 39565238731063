import React from 'react'
import Stack from '@mui/material/Stack'
import SectionLayout from '../../layouts/section-layout/SectionLayout'
import { IStrapiHeader } from '../../types/strapi'
import ImageLink from '../image-link'
import { BackDropBlur, Header } from './HeaderView.styled'
import TopBarSection from '../page-sections/top-bar-section/TopBarSection'
import MobileMenu from '../menu/mobile-menu/MobileMenu'
import DesktopMenu from '../menu/desktop-menu/DesktopMenu'
import Button from '../button'

type IHeaderViewProps = IStrapiHeader & {
  setBlur: (blur: boolean) => void
  hasBlur: boolean
}

const HeaderView = (props: IHeaderViewProps) => {
  const { theme, logo, button, subMenus, links = [], topBar, hasBlur, setBlur } = props
  
  return (
    <>
      <Header>
        <TopBarSection data={topBar} />

        <SectionLayout options={{ theme }}>
          <Stack component='nav' direction='row' justifyContent='space-between' alignItems='center' spacing={{ lg: 8, xl: 12 }} position='relative' height={{ xs: 60, lg: 69 }}>
            <ImageLink {...logo} isLazy={false} sx={{ width: 110, height: 'auto' }} />

            <DesktopMenu subMenus={subMenus} links={links} />
            <Button {...button} sx={{ display: { xs: 'none', lg: 'block' } }} />

            <MobileMenu subMenus={subMenus} links={links} onToggle={setBlur} />

          </Stack>
        </SectionLayout>
      </Header>

      {hasBlur && <BackDropBlur />}
    </>
  )
}

export default HeaderView
