import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { ThemeProvider } from '@mui/material/styles'
import { IStrapiBanner, IStrapiContentPosition } from '../../../types/strapi'
import transformStrapiLink from '../../../utils/transformStrapiLink'
import Link from '../../link'
import Image from '../../image'
import { BannerButton, BannerWrapper, ContentWrapper, PictureContainer, PicturesWrapper } from './MenuBanner.styled'
import useSectionTheme from '../../../hooks/useSectionTheme'
import { BoxProps } from '@mui/material/Box'
import Title from '../../title/Title'

export interface IMenuBannerProps extends IStrapiBanner {
  sx?: BoxProps['sx']
}

const MenuBanner = (props: IMenuBannerProps) => {
  const { theme, pictures, button, title, link, contentPosition, sx } = props

  const mergedTheme = useSectionTheme(theme)

  if (!link) {
    return null
  }

  const picturesStep = (30 / ((pictures.length ?? 2) - 1)) + '%'

  return (
    <ThemeProvider theme={mergedTheme}>
      <BannerWrapper sx={sx}>
        <Link {...transformStrapiLink(link)}>
          <Box px={5} height='100%'>
            <Stack sx={{ height: '100%' }} direction={contentPosition === IStrapiContentPosition.RIGHT ? 'row' : 'row-reverse'} spacing={4}>
              <PicturesWrapper style={{ '--pictures-step': picturesStep } as React.CSSProperties}>
                {pictures.map((picture, index) => (
                  <PictureContainer key={index} placement={index} sx={{ position: index ? 'absolute' : 'relative' }}>
                    <Image image={picture} isLazy={Boolean(index)} sx={{ border: '2px solid #fff', backgroundColor: '#F7F7F730', height: '100%', width: 'auto' }} />
                  </PictureContainer>
                ))}
              </PicturesWrapper>

              <ContentWrapper>
                <Title title={title} fontSize={16} fontWeight={700} lineHeight={'20px'} color='text.secondary' />

                {button && (
                  <BannerButton>{button}</BannerButton>
                )}
              </ContentWrapper>
            </Stack>
          </Box>
        </Link>
      </BannerWrapper>
    </ThemeProvider>
  )
}

export default React.memo(MenuBanner)
