import React, { useEffect } from 'react'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import { bindPopover, PopupState, bindToggle } from 'material-ui-popup-state/hooks'
import MenuIcon from '../../../icons/Menu'
import CloseIcon from '../../../icons/Close'
import { IStrapiSimpleLink, IStrapiSubMenu } from '../../../types/strapi'
import MobileSubMenu from '../mobile-sub-menu/MobileSubMenu'
import transformStrapiLink from '../../../utils/transformStrapiLink'
import { MobileMenuLink, MobileMenuPopover } from './MobileMenu.styled'

export interface IMobileMenuViewProps {
  popupState: PopupState
  subMenus: Array<IStrapiSubMenu>
  links: Array<IStrapiSimpleLink>
  onToggle: (isOpen: boolean) => void
}

const MobileMenuView: React.FC<IMobileMenuViewProps> = (props) => {
  const { popupState, subMenus, links, onToggle } = props

  useEffect(() => {
    onToggle(popupState.isOpen)
  }, [ popupState.isOpen, onToggle ])

  return (
    <>
      <IconButton
        {...bindToggle(popupState)}
        title='menu'
        sx={{ display: { lg: 'none' } }}
      >
        {popupState.isOpen && <CloseIcon sx={{ color: (theme) => theme.palette.text.primary, width: 16, height: 16, p: '2px' }} />}
        {!popupState.isOpen && <MenuIcon sx={{ color: (theme) => theme.palette.text.primary, width: 20, height: 20 }} />}
      </IconButton>

      <MobileMenuPopover
        {...bindPopover(popupState)}
        keepMounted
        disablePortal
        sx={{ display: { lg: 'none' } }}
      >
        <Stack alignItems='center' mt={16}>
          {subMenus.map((subMenu: IStrapiSubMenu, index) => (
            <MobileSubMenu key={index} subMenu={subMenu} onMenuClose={popupState.close} />
          ))}

          {links.map(({ link, label }, index) => (
            <MobileMenuLink key={index} {...transformStrapiLink(link)}>
              {label}
            </MobileMenuLink>
          ))}
        </Stack>
      </MobileMenuPopover>
    </>
  )
}

export default MobileMenuView
