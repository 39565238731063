import React from 'react'
import ReactDOM from 'react-dom/client'
import ThemeWrapper from './src/theme/ThemeWrapper'
import PageLayout from './src/layouts/page-layout/PageLayout'
import { CtaProvider } from './src/contexts/cta-provider/CtaProvider'
import { GatsbyBrowser } from 'gatsby'
import type { IPageContext, IPageDataResponse } from './src/templates/page'
import './src/styles/fonts.css'
import './src/styles/index.css'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <ThemeWrapper>
    <CtaProvider>
      {element}
    </CtaProvider>
  </ThemeWrapper>
)

export const wrapPageElement: GatsbyBrowser<IPageDataResponse, IPageContext>['wrapPageElement'] = ({ element, props }) => (
  <PageLayout pageContext={props.pageContext}>
    {element}
  </PageLayout>
)

export const replaceHydrateFunction: GatsbyBrowser['replaceHydrateFunction'] = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

const FILTERED_CASES_PAGES_REGEXP = /\/cases\/(service|industry)/

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ pathname, routerProps, prevRouterProps }) => {
  const prevPathname = prevRouterProps?.location?.pathname || ''

  // prevent scroll when cases filters applied
  if (prevPathname !== pathname && FILTERED_CASES_PAGES_REGEXP.test(prevPathname) && FILTERED_CASES_PAGES_REGEXP.test(pathname)) {
    return false
  }

  return true // default
}
