import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { IStrapiSubMenu } from '../../../types/strapi'
import { MobileDrawer, MobileSubMenuHeader, MobileSubMenuTitle } from './MobileSubMenu.styled'
import CloseIcon from '../../../icons/Close'
import BackArrow from '../../../icons/BackArrow'
import MenuBanner from '../menu-banner/MenuBanner'
import MenuSection from '../menu-section'

export interface IMobileSubMenuProps {
  subMenu: IStrapiSubMenu
  onMenuClose: () => void
}

const MobileSubMenu: React.FC<IMobileSubMenuProps> = (props: IMobileSubMenuProps) => {
  const { subMenu, onMenuClose } = props
  const { label, sections, banner } = subMenu

  const [isOpen, setOpen] = useState(false)

  const handleToggleSubmenu = () => {
    setOpen(!isOpen)
  }

  const handleMenuClose = () => {
    setOpen(false)
    onMenuClose()
  }

  return (
    <>
      <MobileSubMenuTitle onClick={handleToggleSubmenu}>
        {label}
      </MobileSubMenuTitle>

      <MobileDrawer
        anchor='left'
        open={isOpen}
        keepMounted
        disablePortal
      >
        <MobileSubMenuHeader>
          <IconButton onClick={handleToggleSubmenu} title='back'>
            <BackArrow sx={{ color: (theme) => theme.palette.text.primary, width: 24, height: 24 }} />
          </IconButton>

          <Typography fontSize={18} fontWeight={500}>
            {label}
          </Typography>

          <IconButton onClick={handleMenuClose} title='close'>
            <CloseIcon sx={{ color: (theme) => theme.palette.text.primary, width: 16, height: 16, p: '2px' }} />
          </IconButton>
        </MobileSubMenuHeader>

        <Stack spacing={4} p={4}>
          {sections.map((section, index) => (
            <MenuSection key={index} section={section} onMenuClose={handleMenuClose} />
          ))}

          {banner && (
            <Box pt={10} maxHeight={188}>
              <MenuBanner {...banner} sx={{ mx: 'auto', height: '100%', maxWidth: 400 }} />
            </Box>
          )}
        </Stack>
      </MobileDrawer>
    </>
  )
}

export default React.memo(MobileSubMenu)
