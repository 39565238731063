import { IStrapiImage } from '../types/strapi'
import { getStrapiImageUrl } from './getStrapiImageUrl'

export const getImageSrc = (image?: IStrapiImage): string | undefined => {
  if (!image) {
    return
  }

  const imageFile = image.localFile
  if (!imageFile) {
    return getStrapiImageUrl(image.url)
  }

  return imageFile.childImageSharp?.gatsbyImageData.images.fallback?.src || imageFile.publicURL
}

export const getResponsivePreferredFormat = (image?: IStrapiImage) => {
  return image?.localFile?.childImageSharp?.gatsbyImageData.images.sources?.[0]
}