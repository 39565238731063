import React from 'react'
import Image from '../image'
import { IStrapiBackground, IStrapiMixMode, IStrapiSectionBackground } from '../../types/strapi'
import { useIsMobile } from '../../hooks/useIsMobile'
import useIsLazySection from '../../hooks/useIsLazySection'
import { Box, SxProps, useTheme } from '@mui/material'

export type IBackgroundImageProps = IStrapiBackground & {
  sx?: SxProps
}

const BackgroundImage = (props: IBackgroundImageProps) => {

  const { image, size, mobileOpacity, desktopOpacity, xPosition, yPosition, blur, sx } = props

  const { breakpoints } = useTheme()
  const isMobile = useIsMobile('md')
  const isLazy = useIsLazySection()

  const safeXPosition = xPosition || 'center'
  const safeYPosition = yPosition || 'center'

  const safeMobileOpacity = mobileOpacity ?? 1
  const safeDesktopOpacity = desktopOpacity ?? 1
  const opacityStep = (safeDesktopOpacity - safeMobileOpacity) / 4

  if (!image) {
    return null
  }

  return (
    <Box sx={{
      zIndex: -1,
      position: 'absolute',
      overflow: 'hidden',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: isMobile ? safeMobileOpacity : safeDesktopOpacity,
      ...(opacityStep && {
        [breakpoints.down('xl')]: {
          opacity: Math.round((safeDesktopOpacity - opacityStep) * 10) / 10,
        },
        [breakpoints.down('lg')]: {
          opacity: Math.round((safeDesktopOpacity - (opacityStep * 2)) * 10) / 10,
        },
        [breakpoints.down('md')]: {
          opacity: Math.round((safeDesktopOpacity - (opacityStep * 3)) * 10) / 10,
        },
        [breakpoints.down('sm')]: {
          opacity: safeMobileOpacity,
        },
      }),
      ...(blur && { filter: 'opacity(35%) blur(6px)' }),
      ...(props.mixMode === IStrapiMixMode.OVERLAY && { mixBlendMode: 'overlay' }),
      ...sx,
    }}>
      <Image
        image={image}
        isLazy={isLazy}
        allowInlineSvg={false}
        sx={{
          position: 'absolute',
          ...castBackgroundPosition(props),
        }}
        {...size !== 'auto' && {
          imageProps: {
            objectFit: castImageSize(size),
            objectPosition: `${safeXPosition} ${safeYPosition}`,
          },
          cover: true
        }}
      />
    </Box>
  )
}

export default BackgroundImage

const castBackgroundPosition = (background: IBackgroundImageProps) => {
  const { size, yPosition, xPosition, image } = background

  if (size === 'auto') {
    return {
      width: Math.round(parseInt(image?.width || image?.fields?.width || '')),
      height:  Math.round(parseInt(image?.height || image?.fields?.height || '')),
      left: xPosition === 'left' ? 0 : xPosition === 'center' ? '50%' : 'unset',
      right: xPosition === 'right' ? 0 : 'unset',
      top: yPosition === 'top' ? 0 : yPosition === 'center' ? '50%' : 'unset',
      bottom: yPosition === 'bottom' ? 0 : 'unset',
      ...((xPosition === 'center' || yPosition === 'center') && {
        transform: `translate(${xPosition === 'center' ? '-50%' : '0'}, ${yPosition === 'center' ? '-50%' : '0'})`,
      }),
    }
  }

  return { width: '100%', height: '100%', top: 0, left: 0 }
}

const castImageSize = (size?: IStrapiSectionBackground['size']): React.CSSProperties['objectFit'] => {
  switch (size) {
    case 'cover':
      return 'cover'

    case 'contain':
      return 'contain'

    case 'auto':
    default:
      return 'none'
  }
}
