import React from 'react'
import { IStrapiSimpleLink, IStrapiSubMenu } from '../../../types/strapi'
import useMobileMenu from './useMobileMenu'
import MobileMenuView from './MobileMenuView'

export interface IMobileMenuProps {
  subMenus: Array<IStrapiSubMenu>
  links: Array<IStrapiSimpleLink>
  onToggle: (isOpen: boolean) => void
}

const MobileMenu: React.FC<IMobileMenuProps> = (props) => {
  const { subMenus, links, onToggle } = props

  const popupState = useMobileMenu()

  return (
    <MobileMenuView
      subMenus={subMenus}
      links={links}
      popupState={popupState}
      onToggle={onToggle}
    />
  )
}

export default MobileMenu
